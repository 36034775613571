/* 

Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98


--- 01 TYPOGRAPHY SYSTEM

- Fonts weights 
Default: 400
Medium: 
Semi-bold: 
Bold: 

- Line Heights
Default: 1
Small: 
Medium::

- Letter spacing
Used values: 
-0.75px
-
-

--- 02 COLORS 
            
- Primary: #75826b error: #fa5252  success: #51cf66
- Secondary: #f1f3f5 
- Tints:  btn: #5e6856  #bac1b5
- Shades: 
- Accents:
- Greys: #333 #555 #888 #aaa

--- 05 SHADOWS: rgba(0, 0, 0, 0.08)  
0px 0px 0px 5px #2dae87;
box-shadow: 0px 0px 2px 3px #2dae87

--- 06 BORDER-RADIUS

Default: 
Medium: 

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 12

*/

/********************** Main Nav Section **********************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 9.6rem;

  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.08);
  padding: 0 3.2rem;
  position: relative;
}

.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding-top: 0;
  padding-bottom: 0;
  z-index: 999;
  background-color: #fff;
}

.nav-logo-img {
  height: 7rem;
}

.main-nav-list {
  list-style: none;
  display: flex;
  gap: 4.8rem;
  align-items: center;
}

.nav-link:link,
.nav-link:visited {
  text-decoration: none;
  color: #555;
  font-size: 2rem;
  font-weight: 500;
  transition: all, 0.3s;
  letter-spacing: 0.75px;
}

.nav-link-contact:link,
.nav-link-contact:visited {
  display: flex;
  gap: 1.2rem;
  color: #fff;
  background: radial-gradient(circle at center, #75826b, #99a188);
  border-radius: 9px;
  padding: 0.8rem 2.4rem;
}

.nav-link:hover,
.nav-link:active {
  cursor: pointer;
  color: #aaa;
}

.nav-link-contact:hover,
.nav-link-contact:active {
  background-color: #5e6856;
  color: #fff;
}

/* Mobile Nav */
.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;

  display: none;
}

.icon-mobile-nav {
  width: 4.8rem;
  height: 4.8rem;
}

.icon-mobile-nav[name='close-outline'] {
  display: none;
}

/**********************  Hero Section *********************/
.section-hero {
  background-position: center;
  padding: 0 0 6.4rem 0;

  /* height: 100vh; */
  overflow: hidden;
}

.sticky .section-hero {
  margin-top: 9.6rem;
}

.hero {
  background-color: #f1f3f5;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 3.2rem;
  /* height: 100vh; */
}

.hero-slider-container {
  padding: 2rem;
}

.hero-slider {
  display: flex;
  position: relative;
  max-width: 96rem;
  max-height: 72rem;
  margin: 0 auto;
  border-radius: 9px;
  transition: background-image 1s ease-in-out;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 7px 2px #555;
  overflow: hidden;
}

.hero-slider-img {
  opacity: 0;
  transition: opacity 1s;
  max-width: 100%; /* Add this */
  height: auto; /* Add this */
}
.hero-welcome {
  color: #555;
  padding: 6rem 2rem 13rem 2rem;
  line-height: 1.4;
}

.hero-text {
  color: #75826b;
  font-size: 2.4rem;
}

/************************************** Section About  ************************/

.section-about {
  position: relative;
  padding: 9.6rem 0;
}

.about-text-box {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.about-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.about-box {
  /* min-height: 60rem; */
  max-width: 100rem;
  margin: 0 auto 8rem auto;
  background-color: #f1f3f5;
  border-radius: 9px;
  min-height: 50rem;
}

.about-tab-container {
  display: flex;
  justify-content: center;
  gap: 16rem;
}

.btn-tab {
  transform: translateY(-50%);
}

.btn-tab--active {
  transform: translateY(-75%);
  box-shadow: 0 4px 4px 3px rgba(0, 0, 0, 0.07);
}

.btn-tab:hover,
.btn-tab:active {
  background-color: #5e6856;
}

.btn-tab-content {
  display: none;
  padding: 3.2rem 7rem 6.5rem 7rem;
  font-size: 1.8rem;
  line-height: 1.6;
}

.tab-content--active {
  display: block;
}

.btn-tab-content p {
  color: #666;
}

.tab-content-list {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  list-style: none;
  font-size: 1.8rem;
  margin-bottom: 6.4rem;
}

.list-item {
  display: flex;
  gap: 2.4rem;
  align-items: center;
}

.list-icon {
  height: 3.2rem;
  width: 3.2rem;
  color: #75826b;
}

.btn-close {
  position: absolute;
  background: none;
  border: none;
  right: 1%;
  top: 1%;
}

.btn-close [name='close-outline'] {
  font-size: 2.6rem;
  color: #75826b;
  cursor: pointer;
}

.full-services {
  text-align: center;
  margin-bottom: 2.4rem;
  position: relative;
}

.full-services-link:link,
.full-services-link:visited {
  text-decoration: none;
  color: #bac1b5;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0px 3px 0px -1px #bac1b5;
  transition: color, 0.3s;
}

.full-services-link:hover,
.full-services-link:active {
  color: #75826b;
  box-shadow: 0 2px 0 0#f1f3f5;
}

.services {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 100%;
}

.services-list {
  position: relative;
  display: flex;
  align-items: start;
  gap: 2rem;
  font-size: 1.6rem;
  padding: 3.2rem 2.2rem;
  box-shadow: 0 0 10px 2px #00000014;
  border-radius: 9px;

  display: none;
}

.services-left,
.services-right {
  /* flex: 1;  */
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 50%;
}

/* Open .services-list when .list-open is present */
.list-open .services-list {
  display: flex;
}

.services span .list-icon {
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  background-color: #bac1b5;
  border-radius: 50%;
}

/***************************************** SECTION PORTFOLIO *************************/

.section-portfolio {
  background-color: #f1f3f5;
  padding: 9.6rem 0;
}

.portfolio-box {
  padding: 4.8rem;
  border-radius: 9px;
  background-color: #fff;
}

.portfolio-header {
}

.portfolio-description {
  font-size: 1.8rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 5rem;
  letter-spacing: 0.75px;
}

.slider-container {
  margin-bottom: 5rem;
  max-width: 720rem;
}

.slider {
  max-width: 100%;
  height: 50rem;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}

.slide-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-btn {
  position: absolute;
  cursor: pointer;
  border: none;
  font-size: 2.4rem;
  padding: 1.2rem;
  background-color: #fff;
  border-radius: 50%;
  top: 50%;
  z-index: 10;
}

.slider-btn:hover,
.slider-btn:active {
  background-color: #f1f3f5;
}

.slider-btn--left {
  left: 6%;
  transform: translate(0%, -50%);
}

.slider-btn--right {
  right: 6%;
  transform: translate(0%, -50%);
}

.slider-dots {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  gap: 0.6rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -200%);
}

.dot-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.dot {
  border: none;
  background-color: #f1f3f5;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;

  cursor: pointer;
  transition: all 0.5s;
}

.dot--fill {
  background-color: #333;
}

.worksafe-container {
  text-align: center;
}

.worksafe-img {
  width: 50%;
}

/**********************************************  SECTION CONTACT *********************************/

.section-contact {
  padding: 9.6rem 0 12.4rem 0;
  background: radial-gradient(circle, #75826b, #99a188);
}

.contact {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 6.4rem;
  padding: 5rem;
  background-color: #f1f3f5;
  border-radius: 9px;
}

.contact-box {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.contacts-container {
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  width: 100%;
}

.contact-email,
.contact-tel {
  display: flex;
  gap: 1.6rem;
  width: 100%;
  background: radial-gradient(circle, #75826b, #99a188);
  border-radius: 9px;
  color: #fff;
  align-items: center;
  justify-content: center;
}

.contact-tel {
  margin-top: auto;
}

.email-link,
.tel-link {
  padding: 2rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 3rem;
  color: #fff;
  text-decoration: none;
}

.email-link-msg,
.tel-link-msg {
  display: block;
  font-size: 1.8rem;
  padding: 1rem;
}

.contact-tel:hover .tel-link-msg,
.contact-tel:hover .tel-link-icon,
.contact-email:hover .email-link-msg,
.contact-email:hover .email-link-icon {
  display: none;
}

.contact-tel:hover .tel-link::before,
.contact-email:hover .email-link::before {
  font-size: 1.8rem;
  padding: 1rem;
}

.contact-tel:hover .tel-link::before {
  content: '07525421850';
}

.contact-email:hover .email-link::before {
  content: 'protilingdesign1@gmail.com';
}

.email-link-icon,
.tel-link-icon {
  display: flex;
  align-items: center;
  font-size: 3rem;
}

.form-container {
  position: relative;
}

form[name='contact-form'] {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 3fr;
  gap: 1.6rem;
}

form[name='contact-form'] textarea,
form[name='contact-form'] input {
  width: 100%;
  font-family: inherit;
  color: inherit;
  padding: 0.6rem 1.6rem;
  font-size: 1.8rem;
  border: none;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  white-space: normal;
}

form[name='contact-form'] textarea::placeholder,
form[name='contact-form'] input::placeholder {
  color: #aaa;
}

form[name='contact-form'] *:focus {
  outline: none;

  box-shadow: 0 0 0 0.4rem #75826b;
}

.btn-form {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  max-width: fit-content;
  gap: 0.8rem;
}

/* .btn-form[name='enter-outline'] {
  font-size: 2rem;
} */

.form-msg-container {
  position: absolute;
  max-width: 100%;
  right: 50%;
  bottom: 0;
  transform: translate(50%);
}

.contact-location {
}

.location-address {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1.6;
}

.map-directions {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.4rem;
}

#map {
  border-radius: 6px;
  min-height: 40rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

#map .leaflet-popup-content {
  color: #5e6856;
  font-size: 1.6rem;
}

.btn-directions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  justify-self: start;
}

.btn-map ion-icon[name='compass-outline'] {
  font-size: 3rem;
}

/************* Section Footer ********************************************
*/

.section-footer {
  padding: 12.4rem 0;
  border-top: 2px solid #f1f3f5;
}

.footer-logo {
  margin-bottom: 2.4rem;
}

.footer-img {
  display: block;
  height: 5rem;
}

.company-info {
  text-align: center;
  font-size: 1.4rem;
  color: #aaa;
  line-height: 1.6;
}

.copyright {
  margin-bottom: 1.2rem;
}

.info {
  font-size: 1.2rem;
  line-height: 1.6;
}
